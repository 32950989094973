<template>
  <div class="container">
    <CRow>
      <LoadingGif class="loading-gif" v-if="loading"></LoadingGif>
      <CCol v-if="!loading" col="12">
        <CCardHeader>
          {{ users.length }} <span v-if="users.length === 1">user</span>
          <span v-else>users</span>

          <CButton
            v-if="user.is_admin == 1 || user.is_admin == '1'"
            @click="createUserModal = true"
            class="ml-5"
            color="primary"
          >
            Add New User
          </CButton>
        </CCardHeader>

        <table class="table table-responsive">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Initials</th>
              <th>Email</th>
              <th>Roles</th>
              <th>Rate</th>
              <th v-if="user.is_admin == 1 || user.is_admin == '1'">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(u, i) in users" :key="i">
              <td>{{ i + 1 }}</td>
              <td>
                <input
                  class="form-control"
                  v-if="activeRow.row_num === u.id"
                  type="text"
                  v-model="u.name"
                />
                <p v-else>{{ u.name }}</p>
              </td>

              <td>
                <!-- <input
                class="form-control"
                v-if="activeRow.row_num === u.id"
                type="text"
                v-model="u.initials"
              /> -->
                <p>{{ u.initials }}</p>
              </td>
              <td>
                <input
                  class="form-control"
                  v-if="activeRow.row_num === u.id"
                  type="text"
                  v-model="u.email"
                />
                <p v-else>{{ u.email }}</p>
              </td>
              <td>
                <span v-if="activeRow.row_num !== u.id">
                  <CBadge
                    v-if="u.is_lawyer == 1 || u.is_lawyer == '1'"
                    class="m-1 role_pill"
                    shape="pill"
                    color="success"
                    >Lawyer</CBadge
                  >

                  <CBadge
                    v-if="u.is_admin == 1 || u.is_admin == '1'"
                    class="m-1 role_pill"
                    shape="pill"
                    color="info"
                    >Administrator</CBadge
                  >
                </span>
                <span v-else>
                  <input
                    :checked="u.is_lawyer"
                    v-model="u.is_lawyer"
                    type="checkbox"
                  />
                  Lawyer
                  <br />
                  <input
                    :checked="u.is_admin"
                    v-model="u.is_admin"
                    type="checkbox"
                  />
                  Admin
                </span>
              </td>
              <td>
                <input
                  class="form-control"
                  v-if="activeRow.row_num === u.id"
                  type="number"
                  min="0"
                  v-model="u.rate"
                />
                <p v-else>{{ u.rate }}</p>
              </td>
              <td>
                <CButton
                  v-if="
                    activeRow.row_num !== u.id &&
                    (user.is_admin == 1 || user.is_admin == '1')
                  "
                  @click="modifyUser(u.id)"
                  color="dark"
                  class="mr-2"
                >
                  Modify
                </CButton>

                <CButton
                  @click="saveModifyUser(u)"
                  v-if="activeRow.row_num === u.id"
                  color="success"
                  class="mr-2"
                >
                  Save
                </CButton>
                <CButton
                  @click="deleteUser(u)"
                  v-if="activeRow.row_num === u.id"
                  color="danger"
                  class="mr-2"
                >
                  Remove
                </CButton>
                <CButton
                  @click="cancelModifyUser(u.id)"
                  v-if="activeRow.row_num === u.id"
                  color="warning"
                  class="mr-2"
                >
                  Cancel
                </CButton>
              </td>
            </tr>
          </tbody>
        </table>
      </CCol>
    </CRow>

    <!-- Add New User Modal -->
    <CModal title="Add New User" color="primary" :show.sync="createUserModal">
      <CRow>
        <CCol md="12">
          <CCard>
            <CCardBody>
              <CInput
                description=""
                label="Name"
                horizontal
                autocomplete="name"
                v-model="form.name"
                placeholder="Name"
              />
              <CInput
                description=""
                label="Initials"
                horizontal
                autocomplete=""
                v-model="form.initials"
                placeholder="Initials"
              />
              <CInput
                v-model="form.email"
                label="Email"
                type="email"
                horizontal
                placeholder="Email"
              />

              <CInput
                v-model="form.password"
                label="PIN"
                type="password"
                horizontal
                placeholder="Enter PIN"
              />

              <CInput
                v-model="form.password_confirmation"
                label="Repeat PIN"
                type="password"
                horizontal
                placeholder="Repeat PIN"
              />
            </CCardBody>
          </CCard>
          <CButton @click="save_new_user()" color="primary" block>
            Save
          </CButton>
        </CCol>
      </CRow>
    </CModal>
  </div>
</template>

<script>
import LoadingGif from "./LoadingGif.vue";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "Users",
  components: { LoadingGif },
  data() {
    return {
      createUserModal: false,
      user: JSON.parse(localStorage.getItem("user")),
      loading: true,
      users: [],
      form: {
        name: "",
        initials: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      activeRow: {
        row_num: 0,
      },
    };
  },
  mounted() {
    this.fetch_users();
  },

  methods: {
    save_new_user() {
      console.log("saving..");
      var this_ = this;
      axios
        .post("/users/new", this_.form)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Saved!",
            showConfirmButton: false,
            timer: 1500,
          });
          this_.createUserModal = false;
        })
        .then(function (response) {
          this_.fetch_users();
          this_.createUserModal = false;
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },
    fetch_users() {
      const axios = require("axios");

      this.loading = true;

      var this_ = this;

      axios
        .get("/users")
        .then(function (response) {
          // handle success
          console.log(response);
          this_.users = response.data;
          this_.loading = false;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          this_.loading = false;
        });
    },
    modifyUser(user_id) {
      this.activeRow = {
        row_num: user_id,
      };
      console.log(this.activeRow);
    },
    deleteUser(user) {
      const axios = require("axios");

      // this.activeRow = user_id;
      var this_ = this;

      console.log(this.activeRow);
      console.log(user);
      axios
        .post("/users/delete", { id: user.id })
        .then(function (response) {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Removed!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .then(function () {
          this_.fetch_users();
          this_.activeRow = {
            row_num: 0,
          };
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },
    saveModifyUser(user) {
      const axios = require("axios");

      var this_ = this;

      // this.activeRow = user_id;
      console.log(this.activeRow);
      console.log(user);
      axios
        .post("/users/update", user)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Saved!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .then(function () {
          this_.fetch_users();
          this_.activeRow = {
            row_num: 0,
          };
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },
    cancelModifyUser(user_id) {
      this.activeRow = { row_num: 0 };
      console.log(this.activeRow);
    },
    // rowClicked(item, index) {
    //   this.$router.push({ path: `users/${index + 1}` });
    // },
    // pageChange(val) {
    //   this.$router.push({ query: { page: val } });
    // },
  },
};
</script>

<style scoped>
.role_pill {
  font-size: 12px;
  height: 32px;
  padding: 10px;
}

.loading-gif {
  margin-left: 25%;
  margin-top: 15%;
}
</style>
